<template>
  <v-app>
    <v-app-bar app color="white" elevate-on-scroll>
      <v-spacer></v-spacer>
      <div class="headline">Smart in Media Image Classifier</div>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <Login v-if="!loggedIn" @loggedIn="onLoggedIn" />
      <image-classifier v-if="loggedIn && !isAdmin" :user-id="userId"></image-classifier>
      <admin v-if="loggedIn && isAdmin"></admin>
    </v-main>
  </v-app>
</template>

<script>
import ImageClassifier from "./components/ImageClassifier";
import Admin from "./components/AdminComponent";
import Login from "./components/Login";

export default {
  name: "App",

  components: {
    ImageClassifier,
    // eslint-disable-next-line vue/no-unused-components
    Admin,
    Login,
  },

  data: () => ({
    loggedIn: false,
    userId: null,
    isAdmin: false // Add a new property for the user ID
  }),

  methods: {
    onLoggedIn(user) {
      // Receive the user ID from the Login component
      this.userId = user.userId;
      this.isAdmin = user.isAdmin;
      this.loggedIn = true;
      console.log("User ID in App:", this.userId);
    },
  },
};
</script>
