<template>
  <div class="image-classifier">
    <v-container fluid>
      <!-- Classification Info -->
      <v-row class="align-center justify-end">
        <v-col cols="auto">
          <div class="classification-info">
            {{ classifiedCount }} out of {{ images.length }} already classified
          </div>
        </v-col>
      </v-row>

      <!-- Image Display -->
      <v-row no-gutters class="align-center justify-center mt-3">
        <!-- Last Image -->
        <v-col sm="4" class="d-flex justify-center">
          <div class="image-container">
            <div class="text-center mb-1">Last Image</div>
            <div class="image-wrapper">
              <img
                v-if="previousImage.url"
                :src="previousImage.url"
                alt="Previous Image"
                class="previous-image image-size"
              />
              <div v-if="previousImage.id" class="text-center mt-2 image-name">
                {{ previousImage.id }}
              </div>
              <div v-if="previousImage.classes" class="text-center mt-2">
                {{ previousImage.classes }}
              </div>
            </div>
          </div>
        </v-col>

        <!-- Current Image -->
        <v-col sm="4" class="d-flex justify-center">
          <div class="image-container">
            <div class="text-center mb-1">Current Image</div>
            <div class="image-wrapper">
              <img
                v-if="currentImage.url"
                :src="currentImage.url"
                alt="Current Image"
                class="current-image image-size highlighted-image"
              />
              <div v-if="currentImage.id" class="text-center mt-2 image-name">
                {{ currentImage.id }}
              </div>
              <div v-if="currentImage.classes" class="text-center mt-2">
                {{ currentImage.classes }}
              </div>
            </div>
          </div>
        </v-col>

        <!-- Next Image -->
        <v-col sm="4" class="d-flex justify-center">
          <div class="image-container">
            <div class="text-center mb-1">Next Image</div>
            <div class="image-wrapper">
              <img
                v-if="nextImage.url"
                :src="nextImage.url"
                alt="Next Image"
                class="next-image image-size"
              />
              <div v-if="nextImage.id" class="text-center mt-2 image-name">
                {{ nextImage.id }}
              </div>
              <div v-if="nextImage.classes" class="text-center mt-2">
                {{ nextImage.classes }}
              </div>
            </div>
          </div>
        </v-col>
      </v-row>

      <!-- Classification Options & Comment Field -->
      <v-row class="mt-3">
        <v-col class="text-center">
          <div class="classification-options">
            <v-btn
              v-for="(option, index) in classOptions"
              :key="index"
              color="primary"
              @click="classifyImage(option)"
            >
              {{ option.name }} [{{ option.key }}]
            </v-btn>
          </div>

          <v-textarea
            v-model="comment"
            label="Add a comment"
            rows="1"
            class="mx-auto mt-2"
            ref="commentInput"
            @focus="isCommentFocused = true"
            @blur="isCommentFocused = false"
          ></v-textarea>
        </v-col>
      </v-row>

      <!-- Go to Image Field & Image Size Controls! -->
      <v-row class="align-center justify-space-between mt-3">
        <v-col cols="auto">
          <v-btn color="primary" @click="increaseImageSize">+</v-btn>
          <v-btn color="primary" @click="decreaseImageSize" class="ml-2"
            >-</v-btn
          >
        </v-col>

        <v-col cols="auto">
          Go to Image number:
          <v-text-field
            v-model="goToImageNumber"
            type="number"
            dense
            hide-details
            @keyup.enter="jumpToImage"
            @focus="isImageJumpFocused = true"
            @blur="isImageJumpFocused = false"
          ></v-text-field>
          <v-btn color="primary" @click="jumpToImage" class="ml-2">Go</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
//const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
//const frontendEndpoint = process.env.VUE_APP_FrontEnd_ENDPOINT;
const apiEndpoint = process.env.VUE_APP_SERVER_URL
export default {
  name: "ImageClassifier",
  props: {
    userId: Number,
  },
  data() {
    return {
      //NEW
      images: [], // Initialize images as an empty array
      classifiedImages: [],
      currentIndex: 0, //NEW
      goToImageNumber: null, // to hold the input image number
      isCommentFocused: false,
      isImageJumpFocused: false,
      comment: "", // Add a new property for the comment
      imageSize: "300px",
      classOptions: [
        { key: "Alt+1", name: "Blast" },
        { key: "Alt+2", name: "Normoblast" },
        { key: "Alt+3", name: "Promyelozyt" },
        { key: "Alt+4", name: "Metamyelozyt" },
        { key: "Alt+5", name: "Segmentkern" },
        { key: "Alt+6", name: "Stabkern" },
        { key: "Alt+7", name: "Eosinophiler" },
        { key: "Alt+8", name: "Basophiler" },
        { key: "Alt+9", name: "Monozyt" },
        { key: "Alt+0", name: "Lymphozyt" },
        { key: "Alt+Shift+1", name: "Lymphozyt atypisch reaktiv" },
        { key: "Alt+Shift+2", name: "Lymphozyt atypisch maligne" },
        { key: "Alt+Shift+3", name: "LGL" },
        { key: "Alt+Shift+4", name: "Thrombozyt" },
        { key: "Alt+Shift+5", name: "Erythrozyt" },
        { key: "Alt+Shift+6", name: "Megakaryozyt" },
        { key: "Alt+Shift+7", name: "Kernschatten" },
        { key: "Alt+Shift+8", name: "Unknown Med" },
        { key: "Alt+Shift+9", name: "Unknown Qual" },
        { key: "Alt+Shift+0", name: "Myelozyt" },
        { key: "F13", name: "ReserveClass1" },
        { key: "F14", name: "ReserveClass2" },
        { key: "F15", name: "ReserveClass3" },
        // Add more combinations as neededs "Ctrl+Alt+1"
      ],
      loaded: false,
      keyupListener: null,
    };
  },
  computed: {
    previousImage() {
      const image = this.images[this.currentIndex - 1] || {};
      console.log("Previous image:", image);
      return {
        id: image.id || "", // Add the image ID here
        url: image.url,
        name: image.name || "",
        classes: image.classes || "unclassified", // Add classes here
      };
    },
    currentImage() {
      const image = this.images[this.currentIndex] || {};
      console.log("Current image:", image);
      return {
        id: image.id || "", // Add the image ID here
        url: image.url,
        name: image.name || "",
        classes: image.classes || "unclassified", // Add classes here
      };
    },
    nextImage() {
      const image = this.images[this.currentIndex + 1] || {};
      console.log("Next image:", image);
      return {
        id: image.id || "", // Add the image ID here
        url: image.url,
        name: image.name || "",
        classes: image.classes || "unclassified", // Add classes here
      };
    },
    classifiedCount() {
      return this.images.filter((image) => image.classified === true).length;
    },
  },
  methods: {
    jumpToImage() {
      //const desiredIndex = this.goToImageNumber - 1; // Adjusting for 0-indexed array
      const desiredImage = this.images.find(
        (image) => image.id == this.goToImageNumber
      );
      const desiredIndex = this.images.indexOf(desiredImage);
      if (desiredIndex >= 0 && desiredIndex < this.images.length) {
        this.currentIndex = desiredIndex;
        this.goToImageNumber = null; // Reset the input for next use
      } else {
        alert("Invalid image number");
      }
    },
    classifyImage(classification) {
      const currentImageName = this.currentImage.name;
      const userId = this.userId; // Get the user ID from the props
      const timestamp = new Date().toISOString().replace(/[^0-9]/g, ""); // This will give a string in the format "20221104183740"
      this.saveClassification(
        userId,
        currentImageName,
        classification,
        timestamp
      ); // Pass the timestamp to the saveClassification method
      this.$set(this.images[this.currentIndex], "classes", classification.name);
      this.$set(this.images[this.currentIndex], "classified", true);
      this.currentIndex++;
      this.comment = ""; // Clear the comment after submitting the classification
    },
    async saveClassification(userId, imageName, classification, timestamp) {
      try {
        const response = await axios.post(`${apiEndpoint}/saveClassification`, {
          userId: this.userId,
          imageName,
          classification: classification.name,
          comment: this.comment, // Include the comment in the API request
          timestamp,
        });

        if (response.data.message) {
          console.log(response.data.message);
        } else {
          console.error(
            "Error saving classification: No message received from server"
          );
        }
      } catch (error) {
        console.error("Error saving classification:", error);
      }
    },
    increaseImageSize() {
      let newSize = parseInt(this.imageSize) + 50; // Increase the size by 50px
      this.imageSize = newSize.toString() + "px"; // Update the imageSize property
    },
    decreaseImageSize() {
      let newSize = parseInt(this.imageSize) - 50; // Decrease the size by 50px
      if (newSize < 50) newSize = 50; // Set a minimum size limit (e.g., 50px)
      this.imageSize = newSize.toString() + "px"; // Update the imageSize property
    },
    keyupHandler(event) {
      if (!this.loaded || this.isCommentFocused || this.isImageJumpFocused)
        return;

      if (event.key === "ArrowRight" || event.key === "Right") {
        this.navigateNext(); // Navigate to the next image when right arrow is pressed
        return;
      } else if (event.key === "ArrowLeft" || event.key === "Left") {
        this.navigatePrevious(); // Navigate to the previous image when left arrow is pressed
        return;
      }

      // Start creating the inputKey with an empty string
      let inputKey = "Alt+";
      // Add "Shift+" to the input key if the Shift key is pressed
      if (event.shiftKey) {
        inputKey += "Shift+";
      }

      // Map of number key codes to their respective numbers
      const keyCodeMap = {
        48: "0",
        49: "1",
        50: "2",
        51: "3",
        52: "4",
        53: "5",
        54: "6",
        55: "7",
        56: "8",
        57: "9",
      };

      if (keyCodeMap[event.keyCode]) {
        inputKey += keyCodeMap[event.keyCode];
      } else {
        return;
      }

      const classOption = this.classOptions.find(
        (option) => option.key === inputKey
      );

      if (classOption) {
        event.preventDefault();
        this.classifyImage(classOption);
      }
    },
    navigatePrevious() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },
    navigateNext() {
      if (this.currentIndex < this.images.length - 1) {
        this.currentIndex++;
      }
    },
  },
  watch: {
    imageSize(newValue) {
      document.documentElement.style.setProperty("--image-size", newValue);
    },
  },
  mounted() {
    console.log("Component mounted");
    // Call the API to get unclassified images
    axios
      .post(`${apiEndpoint}/getUnclassifiedImages/`, {
        userId: this.userId,
      })
      .then((response) => {
        const unclassifiedImages = response.data.map((image) => {
          return {
            //url: require(`@/assets/images/${image.filename}`),
            //url: `${apiEndpoint}/assets/images/${image.filename}`,
            url: `./assets/images/${image.filename}`,
            name: image.filename,
            classified: false,
            id: image.id,
          };
        });

        // Call the API to get classified images
        axios
          .post(`${apiEndpoint}/getClassifiedImages`, {
            userId: this.userId,
          })
          .then((response) => {
            const classifiedImages = response.data.map((image) => {
              return {
                //url: require(`@/assets/images/${image.filename}`),
                //url: `${apiEndpoint}/assets/images/${image.filename}`,
                url: `./assets/images/${image.filename}`,
                name: image.filename,
                classified: true,
                id: image.id,
                classes: image.classes,
                comment: image.comment,
              };
            });

            // Concatenate classified and unclassified images
            this.images = classifiedImages.concat(unclassifiedImages);
            this.currentIndex = classifiedImages.length; // Set currentIndex to the first unclassified image

            this.loaded = true; // Indicate that the initial data has been loaded
          })
          .catch((error) => {
            console.error("Error getting classified images:", error);
          });
      })
      .catch((error) => {
        console.error("Error getting unclassified images:", error);
      });

    console.log("User ID in Classifier (from props):", this.$props["user-Id"]);
    console.log("User ID in Classifier:", this.userId);

    document.addEventListener("keyup", this.keyupHandler, true);

    this.$watch(
      "imageSize",
      (newValue) => {
        document.documentElement.style.setProperty("--image-size", newValue);
      },
      { immediate: true }
    );
  },

  beforeDestroy() {
    document.removeEventListener("keyup", this.keyupHandler);
  },
};
</script>

<style scoped>
.highlighted-image {
  border: 6px solid green;
  box-sizing: border-box;
}

.class-label {
  color: black;
}
.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
  text-align: center;
}

.image-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image-size {
  width: var(--image-size);
  height: var(--image-size);
  display: flex;
  align-items: center;
  justify-content: center;
}

.previous-image,
.current-image,
.next-image {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.v-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image-container img {
  max-width: 100%;
  height: auto;
}

.v-btn {
  height: 36px;
  min-width: 100px;
  font-size: 14px;
  padding: 0;
  margin: 0 4px 4px 0;
}

.text-center {
  font-size: 16px;
}

.reduced-top-margin {
  margin-top: 0rem;
}

.classification-info {
  font-size: 16px;
  font-weight: bold;
  margin-top: 16px;
  margin-bottom: 16px;
}
:root {
  --image-size: 400px;
}
</style>
