<template>
  <div class="login">
    <v-container>
      <v-row class="align-center justify-center">
        <v-col sm="6">
          <v-card class="pa-4">
            <v-card-title>Login</v-card-title>
            <v-card-text>
              <v-form ref="form" @submit.prevent="submit">
                <v-text-field
                  v-model="username"
                  label="Username"
                  :rules="[rules.required]"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  label="Password"
                  type="password"
                  :rules="[rules.required]"
                  required
                ></v-text-field>
                <v-alert
                  v-if="errorMessage"
                  type="error"
                  border="left"
                  prominent
                  >{{ errorMessage }}</v-alert
                >
                <v-btn color="primary" type="submit" :disabled="!valid"
                  >Submit</v-btn
                >
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UserLogin",
  data() {
    return {
      userId: null,
      username: "",
      password: "",
      errorMessage: "",
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  computed: {
    valid() {
      return this.username && this.password;
    },
  },
  methods: {
    async submit() {
      try {
        const response = await axios.post(process.env.VUE_APP_SERVER_URL + "/login", {
          username: this.username,
          password: this.password,
        });

        this.userId = response.data.userId;
        console.log(response.data)
        console.log(response.data.isAdmin);


        if (response.data.isAdmin) {
          // If the user is an admin, navigate to the admin page.
          this.$router.replace('/admin');
          console.log("admin");
        } else {
          // If the user is not an admin, navigate to the image classifier page.
          this.$router.replace('/');
          console.log("not admin");
        }

        // Notify parent components about the logged in user. test
        this.$emit("loggedIn", response.data);

      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.errorMessage = "Wrong username or password.";
        } else {
          this.errorMessage = "An error occurred.";
        }
      }
    },
    },
  }
;
</script>

<style scoped>
.login {
  height: 100vh;
}
</style>
