<template>
  <div class="text-center">
    <h1>SIM Object-Classifier Statistics</h1>
    <v-data-table
      :headers="headers"
      :items="statistics"
      class="elevation-1"
    ></v-data-table>
    <v-btn color="green" class="mx-auto" @click="downloadClassifications">Download Classifications</v-btn>
    <v-btn color="red" class="mx-auto" @click="downloadDatabase">Download Database</v-btn>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      headers: [
        { text: 'Username', value: 'username' },
        { text: 'Classified Images', value: 'classifiedImages' },
        { text: 'Total Images', value: 'totalImages' },
      ],
      statistics: [],
    }
  },
  async created() {
    const response = await axios.get(process.env.VUE_APP_SERVER_URL + '/classificationStatistics');
    this.statistics = response.data.map(row => {
  const parts = row.split(' ');
  const username = parts[0];
  const classifiedImages = parseInt(parts[1]);
  const totalImages = parseInt(parts[4]);
  return {
    username,
    classifiedImages,
    totalImages,
  };
});
  },

  methods: {
    async downloadClassifications() {
      const response = await axios.get(process.env.VUE_APP_SERVER_URL + '/exportClassifications', { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'classifications.csv');
      document.body.appendChild(link);
      link.click();
    },
    async downloadDatabase() {
    const response = await axios.get(process.env.VUE_APP_SERVER_URL + '/downloadDatabase', { responseType: 'blob' });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'hematologyai-local-backup.db');
    document.body.appendChild(link);
    link.click();
  },
  },
  
};

</script>

<style scoped>
.text-center {
  text-align: center;
}
</style>
