import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'

// Import the components here
// import ImageClassifier from './components/ImageClassifier.vue' 
import AdminComponent from './components/AdminComponent.vue' 

Vue.config.productionTip = false

// Add these lines
Vue.use(VueRouter)

// Define routes
const routes = [
  // { path: '/', component: ImageClassifier },
  { path: '/admin', component: AdminComponent }
]

// Create the router instance
const router = new VueRouter({
  routes
})

new Vue({
  vuetify,
  router, 
  render: h => h(App)
}).$mount('#app')
